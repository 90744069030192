import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { DeliveryService } from '../../../../src/features/Delivery/delivery.service';
import uploadIcon from '../../../assets/img/upload-white.svg';
import { useAccount } from '../../../hooks';
import { useBrowseNodesByVertical } from '../../../hooks/useMaterialCategory';
import { DropdownSelect } from '../../../shared/Components';
import { useAuthority } from '../../../shared/Components/HasAuthority/HasAuthorityNew';
import { AUTHORITY2 } from '../../../shared/Constants/Constants';

function UploadDocBody({ uploadFn }) {
  const { hasAuth } = useAuthority();
  const isPro = hasAuth([AUTHORITY2.PRO]);
  const isServiceProvider = hasAuth([AUTHORITY2.SERVICE_PROVIDER]);
  const account = useAccount();
  const isRecycler = account?.user?.profile?.vendorType === 'PWP';

  const [recyclers, setRecyclers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [materials, setItems] = useState([]);
  const [selectedItemId, setSelectedItemId] = useState();

  const { serviceProviders, accountDetails } = useSelector(({ serviceProvider, account }) => ({
    serviceProviders: serviceProvider.listLight,
    accountDetails: account.user.profile,
  }));

  const [providerDetails, setProviderDetails] = useState({
    providerId: isPro ? 0 : accountDetails.id,
    providerName: isPro ? '' : accountDetails.serviceProviderName,
  });

  const browseNodesByVertical = useBrowseNodesByVertical();

  useEffect(() => {
    let items = [];
    if (browseNodesByVertical) {
      browseNodesByVertical.forEach((browseItem) => {
        if (browseItem.vertical === 'PLASTIC') {
          browseItem.items.forEach((item) =>
            items.push({
              value: item.id,
              id: item.id,
              label: item.name,
              category: item.browseNodeTitle,
            })
          );
        }
        return true;
      });
    }
    setItems(items);
  }, [browseNodesByVertical?.length]);

  const fetchRecyclers = async () => {
    if (providerDetails.providerId >= 0) {
      //set Loading
      setLoading(true);
      const response = await DeliveryService.getRecyclers({
        customerId: providerDetails.providerId,
      });
      setRecyclers(response.data);
      // Check if there is only one recycler and set it as default
      if (response.data.length === 1) {
        const singleRecycler = response.data[0];
        setRecyclerDetails({
          recyclerId: singleRecycler.id,
          recyclerName: singleRecycler.businessName,
        });
      }
      setLoading(false);
    }
  };

  const [recyclerDetails, setRecyclerDetails] = useState({
    recyclerId: '',
    recyclerName: '',
  });

  useEffect(() => {
    if (isServiceProvider) {
      setProviderDetails({
        providerId: accountDetails.id,
        providerName: accountDetails.serviceProviderName,
      });
    }
  }, [isServiceProvider, accountDetails]);

  useEffect(() => {
    fetchRecyclers();
  }, [providerDetails.providerId]);

  const onDrop = async (event) => {
    if (
      (isPro && recyclers.length > 0 && !recyclerDetails.recyclerId) ||
      (!isRecycler && recyclers.length > 0 && !recyclerDetails.recyclerId)
    ) {
      toast.error('Please Select Recycler ');
      return;
    } else if (isEmpty(selectedItemId)) {
      toast.error('Please Select Material ');
      return;
    }

    uploadFn(
      event.target.files,
      isPro && providerDetails.providerId === 8651
        ? recyclerDetails.recyclerId
        : providerDetails.providerId,
      isPro && providerDetails.providerId === 8651
        ? recyclerDetails.recyclerName
        : providerDetails.providerName,
      isRecycler ? providerDetails.providerId : recyclerDetails.recyclerId,
      isRecycler ? providerDetails.providerName : recyclerDetails.recyclerName,
      selectedItemId
    );
  };

  return (
    <Row>
      {isPro && (
        <Col>
          <Form.Group as={Col}>
            <Form.Label className="label text-regular font-weight-bold">
              Service Provider
            </Form.Label>
            <Select
              className="basic-single"
              classNamePrefix="select"
              isSearchable={true}
              isClearable={true}
              value={serviceProviders.find((i) => i.id === providerDetails.providerId) || null}
              name="serviceProviderId"
              getOptionLabel={(option) => option.businessName}
              getOptionValue={(option) => option.id}
              options={serviceProviders?.filter((sp) => sp?.vendorType === 'SERVICE_PROVIDER')}
              onChange={(value) => {
                setProviderDetails({ providerName: value?.businessName, providerId: value?.id });
                setRecyclerDetails({ recyclerId: '', recyclerName: '' });
              }}
            />
          </Form.Group>
        </Col>
      )}

      {(isPro || !isRecycler) && (
        <Col>
          <Form.Group as={Col}>
            <Form.Label className="label text-regular font-weight-bold">Recycler</Form.Label>
            <Select
              className={`basic-single ${
                formSubmitted && !recyclerDetails.recyclerId ? 'is-invalid' : ''
              }`}
              classNamePrefix="select"
              isSearchable={true}
              isClearable={true}
              isLoading={loading}
              value={
                recyclerDetails.recyclerId &&
                recyclers.find((i) => i.customerId === recyclerDetails.recyclerId)
              }
              name="recyclerId"
              getOptionLabel={(option) => option?.businessName}
              getOptionValue={(option) => option.customerId}
              options={recyclers}
              onChange={(value) => {
                setRecyclerDetails({
                  recyclerId: value?.customerId,
                  recyclerName: value?.businessName,
                });
              }}
              isDisabled={recyclers.length === 0} // Disabled there is no linked recycler
            />
            {formSubmitted && !recyclerDetails.recyclerId && (
              <div className="invalid-feedback">Recycler is Required</div>
            )}
          </Form.Group>
        </Col>
      )}
      <Col>
        <Form.Group as={Col}>
          <Form.Label className="label text-regular font-weight-bold">Material</Form.Label>
          <DropdownSelect
            value={selectedItemId}
            isSearchable={true}
            isClearable={true}
            onChange={(val) => setSelectedItemId(val.id)}
            options={materials}
            noOptionsMessage={() => 'Items not found!'}
            getOptionLabel={(option) => {
              return (
                <div>
                  <div className="text-small font-semibold">{option.label}</div>
                  <div className="text-xsmall">{option.category}</div>
                </div>
              );
            }}
            getOptionValue={(option) => `${option.id},${option.label}`}
          />
        </Form.Group>
      </Col>
      <Col>
        <Form.Group as={Col}>
          <Form.Label className="label text-regular font-weight-bold">Upload Document</Form.Label>
          <label className="upload_label" htmlFor="bulk-upload-input">
            <img src={uploadIcon} alt="upload_icon" />
            Upload File
          </label>
          <input
            accept="application/vnd.rar,application/zip"
            onChange={onDrop}
            id="bulk-upload-input"
            type="file"
            aria-label="drag and drop area"
          />
          {formSubmitted && !recyclerDetails.recyclerId && isPro && (
            <div className="invalid-feedback">Please select a recycler before uploading.</div>
          )}
        </Form.Group>
      </Col>
    </Row>
  );
}

export default UploadDocBody;
