import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { NumberParam, StringParam, useQueryParams, withDefault } from 'use-query-params';
import addIcon from '../../../assets/img/add_icon_white.svg';
import { DeliveryService } from '../../../features/Delivery/delivery.service';
import AccessDenied from '../../../features/Others/AccessDenied/AccessDenied';
import { useAsyncEffect } from '../../../hooks';
import HasAuthority, {
  useAuthority,
} from '../../../shared/Components/HasAuthority/HasAuthorityNew';
import { AUTHORITY2, PAGE_CONFIG } from '../../../shared/Constants/Constants';
import { bulkUploadDocuments } from '../../../shared/Utils/FileUpload';
import './BulkDelivery.scss';
import BulkDeliveryModal from './BulkDeliveryModal';
import BulkDeliveryTable from './BulkDeliveryTable';
import {
  ALLOCATED_BATCH_COLUMN,
  ALLOCATED_BATCH_COLUMN_PRO,
  BATCH_COLUMN,
  BATCH_COLUMN_PRO,
  PROCESSED_BATCH_COLUMN,
  PROCESSED_BATCH_COLUMN_PRO,
  REJECTED_BATCH_COLUMN,
  REJECTED_BATCH_COLUMN_PRO,
} from './Columns/ColumnDef';
import IndividualDeliveryStatusModal from './IndividualDeliveryStatusModal';

const tabToStatusMap = {
  inProgress: 'UPLOADED,UPLOADING,EXTRACTED,IN_PROCESS,INITIATED',
  Processed: 'COMPLETED',
  Allocated: 'ALLOCATED',
  Rejected: 'REJECTED',
};

function BulkDeliveries() {
  let location = useLocation();
  const history = useHistory();
  const { hasAuth } = useAuthority();
  const isPro = hasAuth([AUTHORITY2.PRO]);
  const isServiceProvider = hasAuth([AUTHORITY2.SERVICE_PROVIDER]);
  const [sortBy, setSortBy] = useState(undefined);

  const defaultFilters = {
    pageKey: 0,
    size: 20,
    total: 0,
    pages: 0,
    'batchId.equals': '',
    'providerName.equals': '',
  };
  const queryParamsConfig = {
    pageKey: withDefault(NumberParam, defaultFilters.pageKey),
    size: withDefault(NumberParam, defaultFilters.size),
    total: withDefault(NumberParam, defaultFilters.total),
    pages: withDefault(NumberParam, defaultFilters.pages),
    'batchId.equals': withDefault(StringParam, defaultFilters.batchId),
    'providerName.equals': withDefault(StringParam, defaultFilters.providerName),
  };

  const [paramFilters, setParamFilters] = useQueryParams(queryParamsConfig);
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth();
  const currentDate = new Date().getDate();
  const HASH = location?.hash?.replace('#', '');

  const { serviceProviders, accountDetails, allAccountDetails } = useSelector(
    ({ serviceProvider, account }) => ({
      serviceProviders: serviceProvider.listLight,
      accountDetails: account.user.profile,
      allAccountDetails: account,
    })
  );

  const [tableData, setTableData] = useState([]);
  const [uploaded, setUploaded] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedDel, setSelectedDel] = useState();
  const [batchId, setBatchId] = useState();
  const [status, setStatus] = useState(tabToStatusMap[HASH !== '' ? HASH : 'inProgress']);
  const [recyclers, setRecyclers] = useState([]);

  const fetchRecyclers = async () => {
    const response = await DeliveryService.getRecyclers({
      customerId: 0,
    });
    setRecyclers(response.data);
  };

  useEffect(() => {
    fetchRecyclers();
  }, []);

  const isCurrentYear =
    allAccountDetails?.selectedFinancialYear?.fromDate?.split('-')[0] === currentYear.toString();

  const uploadDate = isCurrentYear
    ? `${currentYear}-${currentMonth}-${currentDate}`
    : allAccountDetails?.selectedFinancialYear?.toDate?.split('T')[0];

  useAsyncEffect(async () => {
    setIsLoading(true);
    getFilteredBatchDetails();
  }, [serviceProviders.length, status, allAccountDetails?.selectedFinancialYear?.fromDate]);

  const updateParams = (page = 0) => {
    const params = {
      size: 20,
      sort: 'uploadedAt,DESC',
      page,
      status: status,
    };
    const newParamFilters = { ...paramFilters, pageKey: page };
    if (!isPro) {
      params['providerId.equals'] = accountDetails?.id;
      newParamFilters['providerId.equals'] = accountDetails?.id;
    }
    if (sortBy) {
      params['sortBy'] = sortBy;
    }
    setParamFilters(newParamFilters);
    return params;
  };

  const spName = (i) =>
    serviceProviders?.find((item) => item?.id === i?.serviceProviderId)?.businessName;

  const mapPayloadNames = (params) => {
    const fields = [
      { id: 'batchId', key: 'batchId.equals' },
      { id: 'serviceProviderName', key: 'serviceProviderId.equals' },
      { id: 'recyclerName', key: 'recyclerId.equals' },
    ];
    const queryParams = fields.reduce((acc, { id, key }) => {
      const item = params?.find((item) => item?.id === id);
      if (item?.value != null) {
        acc[key] = item.value;
      }
      return acc;
    }, {});

    return queryParams;
  };

  const getFilteredBatchDetails = async (args, tableFilters = []) => {
    const param = { ...updateParams(args), ...mapPayloadNames(tableFilters) };
    const result = await DeliveryService.fetchBatchDetails(param);

    if (result.status >= 400) {
      setIsLoading(false);
      return;
    }

    if (result.status === 200) {
      setParamFilters((prev) => ({
        ...prev,
        total: result.headers['x-total-count'],
        pages: Math.ceil(+result.headers['x-total-count'] / PAGE_CONFIG.size),
      }));
      setIsLoading(false);
    }

    const newResult = result.data ?? [];
    const resultJson = newResult?.map((e) => ({ ...e, providerName: spName(e) }));

    setTableData(resultJson ?? []);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleUploadSuccess = () => {
    setShowModal(false);
    getFilteredBatchDetails();
  };

  const handleInProgress = (evn) => {
    const IDX = evn?.target?.getAttribute('id');
    if (!isNaN(+IDX)) {
      setSelectedDel(tableData[IDX]);
      if (IDX) setShowModal(true);
    }
  };

  const discardHandler = async (batchID, remark) => {
    const result = await DeliveryService.discardBatchHandler({ batchId: batchID, remarks: remark });

    if (result.status > 400) {
      toast.error('File deletion failed');
      return;
    }

    if (result.status === 200) {
      handleCloseModal();
      getFilteredBatchDetails();

      toast.success('File deleted successfully');
      return;
    }
  };

  const uploadHandler = async (id, name, content, recylerId, recyclerName, materialId) => {
    const result = await bulkUploadDocuments(
      id,
      name,
      content,
      uploadDate,
      recylerId,
      recyclerName,
      materialId
    );

    if (result.status > 400) {
      toast.error('Upload failed');
      return;
    }

    if (result.status === 200) {
      const batchid = result?.headers?.['x-batchid'];
      setBatchId(batchid);
      setUploaded(2);
      toast.success('Document uploaded successfully');
    }
  };

  const processDocumentHandler = async (batchID) => {
    const result = await DeliveryService.documentProcessHandler({ batchId: batchID });

    if (result.status > 400) {
      toast.error(result?.error?.message);
      return;
    }

    if (result.status === 200) {
      getFilteredBatchDetails();
    }
  };

  const handleFilterChange = (tableFilters) => {
    getFilteredBatchDetails(0, tableFilters);
  };

  const handleSort = async (id, val) => {
    const fields = [
      {
        id: 'readyToAllocateCount',
        key: val === 'asc' ? 'readyToAllocate_ASC' : 'readyToAllocate_DESC',
      },
      { id: 'totalTransactions', key: val === 'asc' ? 'draftDl_ASC' : 'draftDl_DESC' },
      { id: 'totalDeliveries', key: val === 'asc' ? 'allocatedDl_ASC' : 'allocatedDl_DESC' },
    ];
    const sortKey = fields?.find((item) => item?.id === id)?.key;
    setSortBy(sortKey);
  };

  useEffect(() => {
    if (sortBy) {
      getFilteredBatchDetails();
    }
  }, [sortBy]);

  const addRecyclersToColDef = (COL_DEF) => {
    const NEW_COL_DEF = COL_DEF.map((column) => {
      if (column?.accessor === 'recyclerName') {
        return {
          ...column,
          recyclers: recyclers,
        };
      } else return column;
    });
    return NEW_COL_DEF;
  };

  return (
    <>
      <Card.Header className="bg-white border-0 mb-2">
        <Row className="padding-aligned align-self-center">
          <Col className="d-flex align-self-center">
            <h3>Bulk Deliveries</h3>
          </Col>
          <Col className="text-right">
            <HasAuthority
              permissionsAllowed={[
                { groupName: 'Delivery', moduleName: 'Bulk_Upload', name: 'can_create' },
              ]}>
              <Button
                className="primary"
                onClick={() => {
                  setShowModal(true);
                  setSelectedDel();
                }}>
                <div className="bulk-btn">
                  <img src={addIcon} alt="add_icon" />
                  Create Batch
                </div>
              </Button>
            </HasAuthority>
            {selectedDel ? (
              <IndividualDeliveryStatusModal
                show={showModal}
                selectedDel={selectedDel}
                discardFn={discardHandler}
                handleClose={handleCloseModal}
                processFn={processDocumentHandler}
              />
            ) : (
              <BulkDeliveryModal
                setUploaded={setUploaded}
                uploaded={uploaded}
                show={showModal}
                batchId={batchId}
                discardFn={discardHandler}
                uploadHandler={uploadHandler}
                handleClose={handleCloseModal}
                handleUploadSuccess={handleUploadSuccess}
                processDocumentHandler={processDocumentHandler}
              />
            )}
          </Col>
        </Row>
      </Card.Header>
      <Card.Body className="bg-white border-top">
        <HasAuthority
          permissionsAllowed={[
            { groupName: 'Delivery', moduleName: 'Bulk_Upload', name: 'can_view' },
          ]}
          failureComponent={<AccessDenied />}>
          <Tabs
            defaultActiveKey="inProgress"
            activeKey={HASH !== '' ? HASH : 'inProgress'}
            onSelect={(e) => {
              setStatus(tabToStatusMap[e]);
              setParamFilters(defaultFilters, 'replace');
              history.replace(`/bulk-delivery#${e}`);
            }}
            className="mb-3 bulk-delivery">
            <Tab eventKey="inProgress" title="In progress" onClick={(e) => handleInProgress(e)}>
              <BulkDeliveryTable
                id="bulk-delivery-table-inprogress"
                isLoading={isLoading}
                DATA={tableData}
                TABLEPARAM={paramFilters}
                COLUMNDEF={addRecyclersToColDef(isPro ? BATCH_COLUMN_PRO : BATCH_COLUMN)}
                tableStateFn={setParamFilters}
                pageChangeFn={getFilteredBatchDetails}
                customAction={true}
                type="inprogress"
                handleFilterChange={handleFilterChange}
                columnsWithFilter={['batchId', 'serviceProviderName', 'recyclerName']}
              />
            </Tab>
            <Tab eventKey="Processed" title="Processed">
              <BulkDeliveryTable
                id="bulk-delivery-table-processed"
                isLoading={isLoading}
                DATA={tableData}
                TABLEPARAM={paramFilters}
                COLUMNDEF={addRecyclersToColDef(
                  isPro ? PROCESSED_BATCH_COLUMN_PRO : PROCESSED_BATCH_COLUMN
                )}
                tableStateFn={setParamFilters}
                pageChangeFn={getFilteredBatchDetails}
                customAction={true}
                type="processed"
                handleSort={handleSort}
                handleFilterChange={handleFilterChange}
                columnsWithFilter={['batchId', 'serviceProviderName', 'recyclerName']}
              />
            </Tab>
            <Tab eventKey="Allocated" title="Allocated">
              <BulkDeliveryTable
                id="bulk-delivery-table-allocated"
                isLoading={isLoading}
                DATA={tableData}
                TABLEPARAM={paramFilters}
                COLUMNDEF={addRecyclersToColDef(
                  isPro ? ALLOCATED_BATCH_COLUMN_PRO : ALLOCATED_BATCH_COLUMN
                )}
                tableStateFn={setParamFilters}
                pageChangeFn={getFilteredBatchDetails}
                customAction={true}
                type="allocated"
                handleFilterChange={handleFilterChange}
                columnsWithFilter={['batchId', 'serviceProviderName', 'recyclerName']}
              />
            </Tab>
            <Tab eventKey="Rejected" title="Rejected">
              <BulkDeliveryTable
                id="bulk-delivery-table-rejected"
                isLoading={isLoading}
                DATA={tableData}
                TABLEPARAM={paramFilters}
                COLUMNDEF={addRecyclersToColDef(
                  isPro ? REJECTED_BATCH_COLUMN_PRO : REJECTED_BATCH_COLUMN
                )}
                tableStateFn={setParamFilters}
                pageChangeFn={getFilteredBatchDetails}
                customAction={true}
                type="rejected"
                handleFilterChange={handleFilterChange}
                columnsWithFilter={['batchId', 'serviceProviderName', 'recyclerName']}
              />
            </Tab>
          </Tabs>
        </HasAuthority>
      </Card.Body>
    </>
  );
}

export default BulkDeliveries;
