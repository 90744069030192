import { flatMap, groupBy, isEmpty, sortBy, sumBy } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { Accordion, Button, Card, Col, Form, FormControl, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { ReactComponent as AccordianArrow } from '../../assets/img/AccordianArrow.svg';
import PlanningWOsCreationModal from '../../features/Planning/PlanningModule/PlanningWOsCreationModal';
import PlanningTable from '../../features/Planning/SharedComponents/PlanningTable';
import { useServiceProviders } from '../../hooks/useCustomers';
import { DropdownSelect } from '../../shared/Components';
import {
  BRAND_PLANNING_TABS,
  BUSINESS_TYPES_OPTIONS,
  DELIVERY_STATUS,
  VENDOR_SUPPLY_TABS,
} from '../../shared/Constants/Constants';
import { convertToMt, toLocalDate } from '../../shared/Utils/Helper';
import {
  fetchSupplyFromVendors,
  fetchVendorCapacitiesByCustomerId,
  getMappedServiceProviders,
  getSPOverviewByCategory,
} from '../../views/Planning/service';
import './MappingAccordian.scss';
import DemandAndSupply from './MarketPlaceCredits/DemandAndSupply';
import BranwiseUnplannedPlanned from './PlanningModule/BranwiseUnplannedPlanned';
import PlanningTabs from './PlanningTabs';

export default function ItemWiseAccordian(props) {
  const {
    isBrandwiseUnplanned,
    brandwisePlanningTabType,
    handleTabSelect,
    planData,
    demandColumns,
    supplyColumns,
    workOrderForm,
    stateById,
    vendorById,
    groupedData,
    setApiCalled,
    setCallAPI,
    materialCategories,
    supplyTab,
    setSupplyTab,
    addSupplyColumns,
    plannedQty,
  } = props;

  const initialValue = {
    fromDate: new Date(),
    executionMode: 'SERVICE_PROVIDER',
    toCustomerId: '',
    toBrandId: '',
    brandId: '',
    toDate: new Date(),
    poNumber: '',
    poDate: new Date(),
    paymentTerm: null,
    draft: true,
    fulfillmentCycle: { value: '' },
    hasAdvancePo: false,
  };

  const [mappedServiceProviders, setMappedServiceProviders] = useState([]);
  const [selectedRecycler, setSelectedRecycler] = useState({});

  const [selectedMappedSp, setSelectedMappedSp] = useState();

  const [selectedBusinessTypeObj, setSelectedBusinessTypeObj] = useState();

  const [selectedBusinessType, setSelectedBusinessType] = useState();

  const serviceProviderById = useServiceProviders();

  const { selectedFinancialYear } = useSelector(({ account }) => {
    return {
      selectedFinancialYear: account?.selectedFinancialYear,
      financialYears: account?.financialYears,
    };
  });

  const { serviceProviders } = useSelector(({ serviceProvider }) => ({
    serviceProviders: serviceProvider.listLight,
  }));
  const pwpServiceProviders = serviceProviders?.filter((item) => item?.vendorType === 'PWP');
  const browseNodes = useSelector(({ browseNodes }) => {
    return browseNodes;
  });

  const ANY_ITEM_IDS = useMemo(() => {
    const itemIds = [];
    // eslint-disable-next-line no-unused-expressions
    browseNodes?.data?.forEach((cat) => {
      const filtItemIds = cat?.items
        ?.filter((itm) => itm?.name === 'Any')
        ?.map((filtItem) => filtItem?.id);
      itemIds.push(...filtItemIds);
    });
    return itemIds;
  }, [browseNodes?.length]);

  const [searchQuery, setSearchQuery] = useState('');
  const [resetRecyclersData, setResetRecyclersData] = useState([]);
  const [resetServiceProvidersData, setResetServiceProvidersData] = useState([]);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const searchIgnoreCase = (str, query) => {
    return str?.toLowerCase().includes(query?.toLowerCase());
  };

  const handleGoClick = () => {
    if (searchQuery.trim() !== '') {
      let recyclersDatas = '';
      let serviceProvidersDatas = '';

      if (supplyTab === 'recyclers') {
        recyclersDatas = workOrderForm?.values?.recyclers?.filter((item) =>
          searchIgnoreCase(item.vendorName, searchQuery)
        );
        serviceProvidersDatas = workOrderForm?.values?.serviceProviders?.filter((item) =>
          searchIgnoreCase(item.recyclerName, searchQuery)
        );
      } else if (supplyTab === 'serviceProviders') {
        recyclersDatas = workOrderForm?.values?.recyclers?.filter((item) =>
          searchIgnoreCase(item.vendorName, searchQuery)
        );
        serviceProvidersDatas = workOrderForm?.values?.serviceProviders?.filter((item) =>
          searchIgnoreCase(item.vendorName, searchQuery)
        );
      }
      workOrderForm.setValues({
        ...workOrderForm.values,
        recyclers: recyclersDatas,
        serviceProviders: serviceProvidersDatas,
      });
    }
  };

  useEffect(() => {
    if (searchQuery === '') {
      workOrderForm.setValues({
        ...workOrderForm.values,
        recyclers: resetRecyclersData,
        serviceProviders: resetServiceProvidersData,
      });
    }
  }, [searchQuery]);

  const handleClearClick = () => {
    setSearchQuery('');
    workOrderForm.setValues({
      ...workOrderForm.values,
      recyclers: resetRecyclersData,
      serviceProviders: resetServiceProvidersData,
    });
  };

  const [showWoCreationModal, setShowWoCreationModal] = useState(false);
  const [currentAccordionData, setCurrentAccordionData] = useState({
    vendorUnplannedTableData: [],
    clientUnplannedTableData: [],
    vendorPlannedTableData: [],
    clientPlannedTableData: [],
  });

  const [plannedStateId, setPlannedStateId] = useState();
  const [tabKey, setTabKey] = useState('generateWO');
  const [isInnerArrowActive, setIsInnerArrowActive] = useState({
    isArrowActive: false,
    activeArrowValue: null,
    isMarketPlaceAccordionActive: false,
    activeMarketPlaceAccordionValue: null,
  });
  const [generateWoDeliveries, setGenerateWoDeliveries] = useState([]);
  const [allocateWoDelivery, setAllocateWoDelivery] = useState([]);
  const [selectedDemand, setSelectedDemand] = useState([]);
  const [demandSelectedRowIds, setDemandSelectedRowIds] = useState({});
  const [categorySPInfo, setCategorySPInfo] = useState({});
  const [hasItem, setHasItem] = useState();

  useEffect(() => {
    setGenerateWoDeliveries([]);
    setAllocateWoDelivery([]);
  }, [tabKey]);

  const CloseWoCreationModal = () => setShowWoCreationModal(!showWoCreationModal);

  const handleSupplyGenerateWoRow = (selectedFlatRows) => {
    const rows = selectedFlatRows.map((row) => {
      return row?.original;
    });
    setGenerateWoDeliveries(rows);
  };

  const handleDemandRowSelection = (selectedFlatRows) => {
    setSelectedDemand(selectedFlatRows);
  };

  /**
   * @param {returns the unallocataed Qty for every state and item grouped} value
   *
   */
  const fetchUnAllocatedQty = (value) => {
    const qty = sumBy(value?.itemList.map((delivery) => delivery?.qty));
    return (Number(qty) / 1000).toFixed(2);
  };

  const rotateInnerArrow = async (value, isSetInnerAccordion) => {
    setSelectedRecycler();
    setSelectedMappedSp();
    setSelectedBusinessTypeObj();
    setMappedServiceProviders();
    if (isBrandwiseUnplanned) {
      setSupplyTab('recyclers');
      const clientUnplannedTableData = value?.demandData?.filter((data) => data?.qty > 0);
      const plannedQuantities = clientUnplannedTableData.map((item) => item.plannedQty);
      const clientFilteredPlannedTableData = value?.demandData?.filter(
        (data) => data?.plannedQty > 0
      );

      const statewisePlannedSum = Object.entries(
        groupBy(clientFilteredPlannedTableData, 'stateId')
      )?.map(([key, value]) => {
        return {
          stateId: key,
          stateName: stateById[key]?.name,
          qty: sumBy(value, 'plannedQty'),
          browseNodeId: value?.[0]?.browseNodeId,
          itemName: value?.[0]?.itemName,
        };
      });

      const statewisevendorData = Object.entries(
        groupBy(clientFilteredPlannedTableData, 'stateId')
      )?.map(([key, value]) => {
        return {
          stateId: key,
          stateName: stateById[key]?.name,
          vendors: flatMap(value?.map((data) => data?.allocationPlan)),
        };
      });

      setCurrentAccordionData({
        vendorUnplannedTableData: value?.fulfillmentData,
        clientUnplannedTableData: clientUnplannedTableData?.map((data, index) => ({
          ...data,
          id: index + 1,
        })),
        vendorPlannedTableData: statewisevendorData,
        clientPlannedTableData: statewisePlannedSum,
      });

      workOrderForm.setValues({
        vendorData: [],
        clientData: clientUnplannedTableData?.map((data, index) => {
          if (data?.isRowSelected) {
            return {
              ...data,
              isRowSelected: false,
              id: index + 1,
            };
          } else
            return {
              ...data,
              id: index + 1,
            };
        }),
      });

      if (isSetInnerAccordion)
        setIsInnerArrowActive({
          isArrowActive: isInnerArrowActive?.activeArrowValue === value?.id ? false : true,
          activeArrowValue: isInnerArrowActive?.activeArrowValue === value?.id ? null : value?.id,
        });
    } else {
      setIsInnerArrowActive((prevState) => ({
        ...prevState,
        isArrowActive: isInnerArrowActive?.activeArrowValue === value?.id ? false : true,
        activeArrowValue: isInnerArrowActive?.activeArrowValue === value?.id ? null : value?.id,
      }));
      getDemands(value);
    }
  };

  const getDemands = async () => {
    setDemandSelectedRowIds({});
    setSelectedDemand([]);
    setGenerateWoDeliveries([]);
    setAllocateWoDelivery([]);
  };

  const handleTableRowClick = async (rowValue, type) => {
    setSearchQuery('');
    setSelectedRecycler();
    setSelectedMappedSp();
    setSelectedBusinessTypeObj();
    setMappedServiceProviders();
    const payload = {
      year: toLocalDate(selectedFinancialYear?.fromDate, 'YYYY-MM-DD'),
      stateId: rowValue?.original?.stateId,
      categoryId: rowValue?.original?.browseNodeId,
      brandPrice: rowValue?.original?.pricePerUnit,
    };
    const ITEM_ID = ANY_ITEM_IDS?.includes(rowValue?.original?.itemId)
      ? null
      : rowValue?.original?.itemId;
    setHasItem(ITEM_ID);
    try {
      const respvendorData = await fetchSupplyFromVendors(payload);

      const serviceProvidersData = sortBy(
        respvendorData?.data?.filter((vendObj) => vendObj?.serviceProviderId),
        'margin'
      )?.map((data, index) => ({
        ...data,
        isServiceProvider: true,
        vendorName: `${vendorById?.[data?.serviceProviderId]?.name}`,
        recyclerName: vendorById?.[data?.customerId]?.registeredName,
        stateName: stateById[data?.stateId]?.code,
        available: data?.totalQty - data?.plannedQty,
        id: index + 1,
        isRowSelected: false,
        backgroundColor: '#dff2e9',
        itemId: ITEM_ID,
      }));
      const recyclersData = sortBy(
        respvendorData?.data?.filter((vendObj) => !vendObj?.serviceProviderId),
        'margin'
      )?.map((data, index) => ({
        ...data,
        vendorName: `${vendorById?.[data?.customerId]?.name}`,
        stateName: stateById[data?.stateId]?.code,
        available: data?.totalQty - data?.plannedQty,
        id: index + 1,
        isRowSelected: false,
        backgroundColor: '#dff2e9',
        itemId: ITEM_ID,
      }));

      if (type === 'demand') {
        let isSameRowClicked = true;
        const clientData = workOrderForm?.values?.clientData?.map((row, index) => {
          if (row.isRowSelected) {
            row.isRowSelected = false;
            isSameRowClicked = isSameRowClicked ? true : false;
          } else if (index + 1 === rowValue?.original?.id) {
            row.isRowSelected = true;
            isSameRowClicked = false;
          }
          return row;
        });

        const selectedVendorData = workOrderForm?.values?.clientData?.find(
          (row, index) => index + 1 === rowValue?.original?.id
        );

        setResetRecyclersData(recyclersData);
        setResetServiceProvidersData(serviceProvidersData);
        workOrderForm.setValues({
          clientData: clientData,
          // vendorData: sortedVendorDataMargin?.map((data, index) => ({ ...data, id: index + 1 })),
          vendorData: respvendorData?.data,
          recyclers: recyclersData,
          serviceProviders: serviceProvidersData,
          selectedVendorData: selectedVendorData,
        });
      }
    } catch (error) {
      toast.error('some error occured');
    }
  };

  const handleGenerateWorkOrderButtonClick = () => {
    const selectedData = workOrderForm?.values?.[supplyTab]?.filter((data) => data?.isRowSelected);
    workOrderForm.setFieldValue(
      'selectedClientData',
      selectedData?.map((data) => ({ ...data, ...initialValue }))
    );
    setShowWoCreationModal(true);
  };

  const handleClickPlannedState = (plannedStateId) => {
    setPlannedStateId(plannedStateId);
  };

  const isCreateWorkOrderButtonDisabled =
    !workOrderForm?.values?.[supplyTab] ||
    workOrderForm?.values?.[supplyTab]?.filter((data) => data?.isRowSelected)?.length === 0 ||
    workOrderForm?.values?.[supplyTab]?.some(
      (data) =>
        data?.isDemandLess || data?.isVendorAvailableLess || data?.isVendorSummationAllocateGreater
    );

  const isStateAndRateAddedForAddSupply =
    workOrderForm?.values?.[supplyTab]?.[0]?.stateId &&
    workOrderForm?.values?.[supplyTab]?.[0]?.isRowSelected &&
    workOrderForm?.values?.[supplyTab]?.[0]?.rate &&
    workOrderForm?.values?.[supplyTab]?.[0]?.allocate;

  const handleGenerateWoAgainstDeliveries = () => {
    const values = {
      selectedClientData: [
        {
          fromDate: '',
          executionMode: 'SERVICE_PROVIDER',
          toDate: '',
          poNumber: '',
          poDate: '',
          paymentTerm: null,
          draft: true,
          fulfillmentCycle: null,
          sac: null,
        },
      ],
      selectedDeliveriesData: generateWoDeliveries,
    };

    workOrderForm.setValues({
      ...workOrderForm?.values,
      recyclers: [],
      serviceProviders: [],
      selectedClientData: values?.selectedClientData,
      selectedDeliveriesData: values?.selectedDeliveriesData,
    });
    setShowWoCreationModal(!showWoCreationModal);
  };

  useEffect(() => {
    if (isBrandwiseUnplanned && !isEmpty(workOrderForm?.values?.clientData)) {
      const selectedItemId = planData?.itemsList?.find(
        (data) => data?.itemCategory === workOrderForm?.values?.clientData?.[0]?.browseNodeId
      );
      rotateInnerArrow(selectedItemId, false);
    }
  }, [planData]);

  const getFromDate = (fromDate) => {
    const sortedDeliveryDates = sortBy(
      generateWoDeliveries.map((delivery) => delivery?.deliveryDate)
    );
    if (fromDate) return sortedDeliveryDates[0];
    else return sortedDeliveryDates[sortedDeliveryDates.length - 1];
  };

  const getSPOverviewData = async () => {
    const result = await Promise.all(
      planData?.itemsList.map((data) => {
        return getSPOverviewByCategory({
          categoryId: data?.itemCategory,
          year: toLocalDate(selectedFinancialYear?.fromDate, 'YYYY-MM-DD'),
        });
      })
    );
    const categoryDataMap = {};
    // eslint-disable-next-line no-unused-expressions
    planData?.itemsList?.forEach((catData, index) => {
      categoryDataMap[catData?.itemCategory] = result?.at(index).data;
    });
    setCategorySPInfo(categoryDataMap);
  };

  useEffect(() => {
    if (planData?.itemsList?.length) {
      getSPOverviewData();
    }
  }, [planData?.itemsList?.length]);

  const handleSupplyTabs = (eventKey) => {
    const modifiedData = workOrderForm?.values?.[supplyTab]?.map((item) => ({
      ...item,
      isRowSelected: false,
      allocate: null,
    }));
    workOrderForm.setFieldValue(`${supplyTab}`, modifiedData);
    setSupplyTab(eventKey);
  };

  const handleSelection = async (e) => {
    workOrderForm.setFieldValue('addSupply', []);
    setSelectedMappedSp();
    const vendorResponse = await fetchVendorCapacitiesByCustomerId({ 'customerId.equals': e.id });
    setSelectedRecycler(e);
    const selectedVendorObj = vendorResponse?.[0];
    setSelectedBusinessTypeObj(selectedVendorObj);

    let mappedSps = [];

    if (selectedVendorObj?.businessType !== 'DIRECT') {
      mappedSps = await getMappedServiceProviders(e.id);

      const spOptions = mappedSps?.map((item) => ({
        id: item?.serviceProviderId,
        name: serviceProviderById?.[item?.serviceProviderId]?.name,
        label: serviceProviderById?.[item?.serviceProviderId]?.name,
      }));

      setMappedServiceProviders(spOptions);
    }

    if (selectedVendorObj?.businessType === 'DIRECT') {
      setSelectedMappedSp();
      setMappedServiceProviders();
      updateSuppyData(selectedVendorObj);
    }
  };

  const handleBothCaseSelection = async (e) => {
    let mappedSps = [];
    setSelectedMappedSp();
    workOrderForm.setFieldValue('addSupply', []);
    if (e?.business === 'THROUGH_SERVICE_PROVIDER') {
      mappedSps = await getMappedServiceProviders(selectedRecycler.id);

      const spOptions = mappedSps?.map((item) => ({
        id: item?.serviceProviderId,
        name: serviceProviderById?.[item?.serviceProviderId]?.name,
        label: serviceProviderById?.[item?.serviceProviderId]?.name,
      }));

      setMappedServiceProviders(spOptions);
    }

    if (e.business === 'DIRECT') {
      updateSuppyData(selectedBusinessTypeObj, null, e);
    } else {
    }
    setSelectedBusinessType(e);
  };

  const updateSuppyData = (
    selectedBusinessData,
    selectedServiceProvider,
    selectedBothCaseBusinessType
  ) => {
    const selectedClientData = workOrderForm?.values?.selectedVendorData;

    const ITEM_ID = ANY_ITEM_IDS?.includes(selectedClientData?.itemId)
      ? null
      : workOrderForm?.values?.selectedVendorData?.itemId;
    setHasItem(ITEM_ID);
    /**
     * making this dummy quantity to bypass the existing conditions
     * need to remove this quantity
     */
    const DUMMY_QTY = 10000000000;

    const isDirect =
      selectedBusinessData?.businessType === 'BOTH'
        ? selectedBothCaseBusinessType?.business === 'DIRECT'
        : selectedBusinessData?.businessType === 'DIRECT';

    const supplyObj = {
      available: DUMMY_QTY,
      backgroundColor: '#dff2e9',
      categoryId: Number(selectedClientData?.browseNodeId),
      customerId: selectedBusinessData?.customerId,
      isRowSelected: false,
      isServiceProvider: isDirect ? false : true,
      itemId: ITEM_ID,
      margin: 100,
      id: 1,
      plannedQty: 0,
      rate: null,
      recyclerName: vendorById?.[selectedBusinessData?.customerId]?.registeredName, //needs to be confirmerd
      serviceProviderId: isDirect ? null : selectedServiceProvider?.id,
      stateId: selectedClientData?.stateId,
      stateName: stateById[selectedClientData?.stateId]?.code,
      totalQty: DUMMY_QTY,
      vendorName: selectedServiceProvider?.name,
      hasState: selectedClientData?.stateId,
    };

    const supplyData = [supplyObj];
    workOrderForm.setFieldValue('addSupply', supplyData);
  };

  return (
    <div>
      {!isBrandwiseUnplanned ? (
        <div>
          {groupedData.map((value) => {
            return (
              <div className="accordianSpace">
                <Accordion
                  defaultActiveKey={false}
                  activeKey={
                    isInnerArrowActive?.isArrowActive
                      ? isInnerArrowActive?.activeArrowValue?.toString()
                      : null
                  }>
                  <>
                    <Card className="Card">
                      <Accordion.Toggle
                        as={Card.Header}
                        eventKey={(value?.id).toString()}
                        className="innerAccordian"
                        activeKey={
                          isInnerArrowActive?.isArrowActive
                            ? isInnerArrowActive?.activeArrowValue?.toString()
                            : null
                        }
                        onClick={(event) => rotateInnerArrow(value, event)}>
                        <Row>
                          <Col sm="6">
                            <p>
                              {value?.itemName}({fetchUnAllocatedQty(value)} MT)-{value.stateName}
                            </p>
                          </Col>
                          <Col sm="6">
                            <div className="AccordianArrow">
                              <AccordianArrow
                                style={{
                                  transform:
                                    isInnerArrowActive?.isArrowActive &&
                                    isInnerArrowActive?.activeArrowValue === value?.id &&
                                    `rotate(-180deg)`,
                                }}
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <p className="subHeading">
                            Total no of vendors: {value?.recyclerList?.length} | Total no of
                            Deliveries: {value?.itemList?.length}
                          </p>
                        </Row>
                      </Accordion.Toggle>
                      <Accordion.Collapse
                        eventKey={(value?.id).toString()}
                        activeKey={
                          isInnerArrowActive?.isArrowActive
                            ? isInnerArrowActive?.activeArrowValue?.toString()
                            : null
                        }>
                        <Card.Body>
                          <div className="ItemWisecard">
                            <DemandAndSupply
                              supplyData={value}
                              handleGenerateRowSelection={handleSupplyGenerateWoRow}
                              handleDemandRowSelection={handleDemandRowSelection}
                              setGenerateWoDeliveries={setGenerateWoDeliveries}
                              setAllocateWoDelivery={setAllocateWoDelivery}
                              generateWoDeliveries={generateWoDeliveries}
                              isInnerArrowActive={isInnerArrowActive}
                              setIsInnerArrowActive={setIsInnerArrowActive}
                              tabKey={tabKey}
                              setTabKey={setTabKey}
                              handleGenerateWoAgainstDeliveries={handleGenerateWoAgainstDeliveries}
                              allocateWoDelivery={allocateWoDelivery}
                              selectedDemand={selectedDemand}
                              setSelectedDemand={setSelectedDemand}
                              workOrderForm={workOrderForm}
                              setDemandSelectedRowIds={setDemandSelectedRowIds}
                              demandSelectedRowIds={demandSelectedRowIds}
                              setApiCalled={setApiCalled}
                              getDemands={getDemands}
                            />
                          </div>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </>
                </Accordion>
              </div>
            );
          })}
        </div>
      ) : (
        planData?.itemsList?.map((data, id) => {
          const category = materialCategories?.find((cat) => cat?.id === data?.itemCategory)?.title;
          let unPlannedQty = `${convertToMt(Number(plannedQty[id]?.unPlannedQty), 3)} MT`;
          let plannedQtys = `${convertToMt(Number(plannedQty[id]?.plannedQty), 3)} MT`;

          return (
            <Accordion
              defaultActiveKey={false}
              activeKey={
                isInnerArrowActive?.isArrowActive ? isInnerArrowActive?.activeArrowValue : null
              }>
              <Card className="Card">
                <Accordion.Toggle
                  as={Card.Header}
                  eventKey={data?.id}
                  className="innerAccordian"
                  onClick={() => rotateInnerArrow(data, true)}
                  activeKey={
                    isInnerArrowActive?.isArrowActive ? isInnerArrowActive?.activeArrowValue : null
                  }>
                  <Row>
                    <Col sm="12" md="12">
                      <div style={{ display: 'flex' }}>
                        <Col sm="10" md="10">
                          <div>
                            <p>
                              <b>
                                {data?.itemName === 'Any' && `${category} /`}
                                {`${data?.itemName} (${convertToMt(data?.totalQty, 3)} MT)`} |
                                &nbsp;Planned Quantity: {plannedQtys} | &nbsp;Unplanned Quantity:{' '}
                                {unPlannedQty}
                              </b>
                              {/* | Target States :
                              {data?.statesName
                                ?.map((item) => (item === undefined ? 'Geography Neutral' : item))
                                .join(', ')} */}
                            </p>
                          </div>
                        </Col>
                        <Col sm="2">
                          <div className="d-flex float-right">
                            <div
                              className={`${
                                data.status === 'IN_PROGRESS' ? 'tagYellow' : 'tagGreen'
                              } `}>
                              <p>{DELIVERY_STATUS[data.status]?.displayText}</p>
                            </div>
                            <AccordianArrow
                              className="mx-2"
                              style={{
                                transform:
                                  isInnerArrowActive?.isArrowActive &&
                                  isInnerArrowActive?.activeArrowValue === data?.id &&
                                  `rotate(-180deg)`,
                              }}
                            />
                          </div>
                        </Col>
                      </div>
                    </Col>
                  </Row>
                  <Row className="subHeading">
                    <p>
                      {`Supply: No of Service Providers: ${
                        categorySPInfo?.[data?.itemCategory]?.serviceProvidersCount
                      } | 
                        Total Capacity: ${
                          convertToMt(
                            categorySPInfo?.[data?.itemCategory]?.serviceProvidersQty,
                            3
                          ) || 0
                        } MT`}
                    </p>
                  </Row>
                </Accordion.Toggle>
                <Accordion.Collapse
                  eventKey={data?.id}
                  activeKey={
                    isInnerArrowActive?.isArrowActive ? isInnerArrowActive?.activeArrowValue : null
                  }>
                  <Row>
                    <Card.Body>
                      <Card>
                        <Row
                          style={{
                            marginRight: '-5%',
                            marginLeft: '0%',
                          }}>
                          <Col sm="5" md="5" className="mx-n3">
                            <div className="brandDemandHeader">
                              <p className="brandDemandHeaderText">Demand</p>
                            </div>
                            <Row>
                              <div className="mt-3 ml-5">
                                <PlanningTabs
                                  tabArray={BRAND_PLANNING_TABS}
                                  handleTabSelect={handleTabSelect}
                                  activeKey={brandwisePlanningTabType}
                                />
                              </div>
                            </Row>
                            <div className="itemwiseAccordionDemandTableSection">
                              {brandwisePlanningTabType === 'Unplanned' ? (
                                <PlanningTable
                                  tableColumns={demandColumns}
                                  tableData={workOrderForm?.values?.clientData}
                                  HeaderColor={false}
                                  workOrderForm={workOrderForm}
                                  isRowClickRequired={true}
                                  handleTableRowClick={handleTableRowClick}
                                  selectedRowIds={{}}
                                  tableType="demand"
                                  totalCount={workOrderForm?.values?.clientData?.length}
                                  isPaginationRequired={false}
                                />
                              ) : (
                                <BranwiseUnplannedPlanned
                                  isDemand={true}
                                  plannedStateId={plannedStateId}
                                  plannedData={currentAccordionData}
                                  stateById={stateById}
                                  vendorById={vendorById}
                                  handleClickPlannedState={handleClickPlannedState}
                                  materialCategories={materialCategories}
                                />
                              )}
                            </div>
                          </Col>
                          <Col sm="7" md="7" className="mx-n3">
                            <row>
                              <div className="vendorSupplyHeader">
                                <p className="brandDemandHeaderText">
                                  Supply:{' '}
                                  {workOrderForm?.values?.selectedVendorData?.isRowSelected &&
                                  workOrderForm?.values?.selectedVendorData?.stateName === undefined
                                    ? 'Geography Neutral'
                                    : workOrderForm?.values?.selectedVendorData?.stateName}
                                </p>
                              </div>
                            </row>

                            <div className="itemwiseAccordionSupplyTableSection">
                              {brandwisePlanningTabType === 'Unplanned' ? (
                                <div>
                                  <Row>
                                    <div className="ml-5 mb-0">
                                      <PlanningTabs
                                        tabArray={VENDOR_SUPPLY_TABS}
                                        handleTabSelect={handleSupplyTabs}
                                        activeKey={supplyTab}
                                      />
                                    </div>
                                  </Row>
                                  {supplyTab !== 'addSupply' ? (
                                    <div className="d-flex justify-content-start align-items-center">
                                      <Form.Group
                                        className="mb-0"
                                        style={{
                                          marginRight: '0.5rem',
                                          marginLeft: '1.3rem',
                                          marginTop: '0.5rem',
                                        }}>
                                        <FormControl
                                          style={{ width: '150px' }}
                                          placeholder="Search Vendor Name"
                                          value={searchQuery}
                                          onChange={handleSearchChange}
                                        />
                                      </Form.Group>

                                      {/* Buttons section */}
                                      <div
                                        className="d-flex align-items-center"
                                        style={{ gap: '0.3rem', marginTop: '0.5rem' }}>
                                        <Button
                                          variant="primary"
                                          onClick={handleGoClick}
                                          disabled={!searchQuery.trim()}>
                                          Go
                                        </Button>
                                        <Button variant="secondary" onClick={handleClearClick}>
                                          Clear
                                        </Button>
                                      </div>
                                    </div>
                                  ) : (
                                    <div>
                                      <Form.Group className="p-4 d-flex align-items-center">
                                        <div style={{ width: '200px' }} className="mr-2">
                                          <Form.Label className="label_wodetails">
                                            Recycler{' '}
                                          </Form.Label>
                                          <DropdownSelect
                                            className="form-control-one"
                                            onChange={(e) => handleSelection(e)}
                                            // isDisabled={isProgress}
                                            value={
                                              !isEmpty(selectedRecycler) ? selectedRecycler : null
                                            }
                                            options={pwpServiceProviders}
                                            getOptionLabel={(option) => `${option.registeredName}`}
                                            getOptionValue={(option) => `${option.id}`}
                                          />
                                        </div>

                                        {selectedBusinessTypeObj?.businessType === 'BOTH' && (
                                          <div className="mr-2">
                                            <Form.Label className="label_wodetails">
                                              Business through{' '}
                                            </Form.Label>
                                            <DropdownSelect
                                              className="form-control-one"
                                              onChange={(e) => {
                                                handleBothCaseSelection(e);
                                              }}
                                              value={selectedBusinessType ?? ''}
                                              options={BUSINESS_TYPES_OPTIONS}
                                              getOptionLabel={(option) => `${option.name}`}
                                              getOptionValue={(option) => `${option.id}`}
                                            />
                                          </div>
                                        )}
                                        {!isEmpty(selectedBusinessTypeObj?.businessType) &&
                                          (selectedBusinessTypeObj?.businessType ===
                                            'THROUGH_SERVICE_PROVIDER' ||
                                            selectedBusinessType?.business ===
                                              'THROUGH_SERVICE_PROVIDER') && (
                                            <div>
                                              <Form.Label className="label_wodetails">
                                                Mapped service provider{' '}
                                              </Form.Label>
                                              <DropdownSelect
                                                className="form-control-one"
                                                onChange={(e) => {
                                                  setSelectedMappedSp(e);
                                                  updateSuppyData(selectedBusinessTypeObj, e);
                                                }}
                                                value={selectedMappedSp ?? ''}
                                                options={mappedServiceProviders}
                                                getOptionLabel={(option) => `${option.name}`}
                                                getOptionValue={(option) => `${option.id}`}
                                              />
                                            </div>
                                          )}
                                      </Form.Group>
                                    </div>
                                  )}
                                  <PlanningTable
                                    tableColumns={
                                      supplyTab === 'addSupply' ? addSupplyColumns : supplyColumns
                                    }
                                    tableData={workOrderForm?.values?.[supplyTab] || []}
                                    HeaderColor={false}
                                    workOrderForm={workOrderForm}
                                    isRowClickRequired={false}
                                    handleTableRowClick={handleTableRowClick}
                                    selectedRowIds={{}}
                                    tableType="supply"
                                    totalCount={workOrderForm?.values?.[supplyTab]?.length}
                                    isPaginationRequired={false}
                                  />
                                </div>
                              ) : (
                                <BranwiseUnplannedPlanned
                                  isDemand={false}
                                  plannedData={currentAccordionData}
                                  vendorById={vendorById}
                                  stateById={stateById}
                                  plannedStateId={plannedStateId}
                                />
                              )}
                            </div>
                            {/* </Card> */}
                          </Col>
                        </Row>
                      </Card>
                      <Card>
                        <Row className="d-flex justify-content-end m-2">
                          <Button
                            disabled={
                              supplyTab === 'addSupply'
                                ? !isStateAndRateAddedForAddSupply
                                : isCreateWorkOrderButtonDisabled
                            }
                            onClick={handleGenerateWorkOrderButtonClick}>
                            Create Wos against vendor
                          </Button>
                        </Row>
                      </Card>
                    </Card.Body>
                  </Row>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          );
        })
      )}
      {showWoCreationModal && (
        <PlanningWOsCreationModal
          isAddSupply={supplyTab === 'addSupply'}
          showModal={showWoCreationModal}
          handleCloseModal={CloseWoCreationModal}
          workOrderForm={workOrderForm}
          isMarketPlace={!isBrandwiseUnplanned}
          generateWoDeliveries={generateWoDeliveries}
          getFromDate={getFromDate}
          setApiCalled={setApiCalled}
          setCallAPI={setCallAPI}
          handleTabSelect={handleTabSelect}
          planData={planData}
          hasItem={hasItem}
        />
      )}
    </div>
  );
}
