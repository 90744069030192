export const deliverySeq = {
  LOCATION: 'locationDetails',
  ULB: 'ulbDetails',
  SCHEDULE: 'scheduleDetails',
  SUPERVISOR: 'supervisorDetails',
  TRANSPORTER: 'transporterDetails',
};

export const REGEX = {
  EMAIL: '^[ ]*[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}[ ]*$', // in email we are allowing heading & trailing whitespaces
  ALPHA_NUMERIC_WITHOUT_SPACES: '^[ ]*[a-zA-Z0-9]+[ ]*$', // without space in between
  MOBILE_NO: '^[ ]*[0-9]{10,10}[ ]*$', // number (min/max 10) without spaces allowing heading & trailing whitespaces.
  MOBILE_NO_WITHOUT_SPACES: '^[0-9]{10,10}$',
  MOBILE_NO_CHECK_STARTING_DIGIT: '^(9|8|7|6){1}[0-9]{9}$', // number (min/max 10) without spaces & number start with 9 or 8 or 7 or 6.
  UPI_ID: '^\\w+@\\w+$',
  PIN_CODE: '^[ ]*[1-9][0-9]{5}[ ]*$',
  GSTIN: '^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$',
  GSTIN_NO: '\\d{2}[A-Z]{5}\\d{4}[A-Z]{1}[A-Z\\d]{1}[Z]{1}[A-Z\\d]{1}',
  PAN: '[A-Za-z]{5}\\d{4}[A-Za-z]{1}',
  IFSC: '^[ ]*[A-Za-z]{4}0[A-Z0-9a-z]{6}[ ]*$',
  NAME: "^[ ]*[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*[ ]*$",
  ACCOUNT_NUMBER: '^[0-9]{9,18}$',
  ONLY_NUMBER: '^[0-9]$',
  ALPHA_NUMERICS: '^[a-zA-Z0-9]+$',
  TRANSACTION_ID: /^TX(ID)?\d+$/,
  PIBO: /^[a-zA-Z0-9]{10,20}$/,
  CPCB: /^[a-zA-Z0-9]{11,26}$/,
  PWM: /^[a-zA-Z0-9-]{11,30}$/,
};

export const ROUTES = {
  NAVIGATORS: {
    AUTH: 'AuthNavigator',
    HOME: 'HomeNavigator',
  },
  INDEX: 'Index',
  COMMON: 'Common',
  LOGIN: 'Login',
  OTP: 'VerifyOTP',
  HOME: 'Home',
};

export const appMaterialItems = `RPPW007,NRPW001,RPPW005`;
