import axios from 'axios';
import { toast } from 'react-toastify';
import {
  default as APIEndpoints,
  default as ApiEndpoints,
} from '../../shared/Config/ApiEndpoints.const';
import AppAnalytics from '../../shared/Config/AppAnalytics';
import PushNotification from '../../shared/Config/push-notification';
import { ChatService } from '../../shared/Services/ChatService';
import { API_BASE_URL, API_TRACKING_BASE_URL } from '../Config/Api';
import errorExtractor from '../Utils/ErrorExtractor';

axios.defaults.baseURL = process.env.REACT_APP_API_ENDPOINT;
axios.defaults.headers.common.appCode = 'EPR005';
const token = localStorage.getItem('authenticate_token');
axios.defaults.headers.common.Authorization = `Bearer ${token}`;
axios.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  async function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    // console.log('error from interceptor',JSON.stringify(error));
    if (error?.response?.status === 400) {
      const err = errorExtractor(error?.response);
      if (err?.message) {
        if (err?.violations)
          toast.warn(`${err?.violations[0]?.field} : ${err?.violations[0]?.message}`);
        else if (err?.message === 'Required Authorization. Please contact our support team') {
          localStorage.clear();
          delete axios.defaults.headers.common.Authorization;
          AppAnalytics.clearUserProperties();
          await PushNotification.deleteToken();
          await ChatService().clearUserData();
        } else toast.warn(err?.message, { autoClose: 5000 });
      }
      return error.response;
    }

    if (
      error?.response?.status === 401 &&
      error?.response?.data?.message === 'Invalid Credentials. Please Retry'
    ) {
      toast.error(error?.response?.data?.message);
      return error?.response?.data?.message;
    }
    // alert(JSON.stringify(error));
    throw error.response;
  }
);
/*
axios.interceptors.request.use(request => {
  console.log('Starting Request', JSON.stringify(request, null, 2))
  return request
});

*/

export async function getTransporter(id) {
  axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
    'authenticate_token'
  )}`;
  const endpoint = id ? APIEndpoints.TRANSPORTER + `/${id}` : APIEndpoints.TRANSPORTER;
  const response = (await axios.get(endpoint)).data;
  return response;
}

export async function getPickupLocations(payload) {
  const endpoint = APIEndpoints.PICKUPLOCATION + `?size=1000&customerId.equals=` + payload;
  const response = (await axios.get(endpoint)).data;
  return response;
}

export async function updateTransporter(payload) {
  const endpoint = APIEndpoints.TRANSPORTER;
  const response = (await axios.put(endpoint, payload)).data;
  return response;
}

export async function addTransporter(payload) {
  const endpoint = APIEndpoints.TRANSPORTER;
  const response = (await axios.post(endpoint, payload)).data;
  return response;
}
export async function deleteTransporter(id) {
  const endpoints = APIEndpoints.TRANSPORTER + `/${id}`;
  return (await axios.delete(endpoints)).data;
}

export async function getDriver() {
  const endpoint = APIEndpoints.DRIVER;
  const response = (await axios.get(endpoint)).data;
  return response;
}

export async function updateDriver(payload) {
  const endpoint = APIEndpoints.DRIVER;
  const response = (await axios.put(endpoint, payload)).data;
  return response;
}

export async function addDriver(payload) {
  const endpoint = APIEndpoints.DRIVER;
  const response = (await axios.post(endpoint, payload)).data;
  return response;
}

export async function deleteDriver(id) {
  const endpoint = APIEndpoints.DRIVER + `/${id}`;
  const response = (await axios.delete(endpoint)).data;
  return response;
}

export async function getSuperviors(payload) {
  const endpoint = APIEndpoints.SUPERVISORS;
  const response = (await axios.get(endpoint, payload)).data;
  return response;
}

export async function updateSupervisor(payload) {
  const endpoints = APIEndpoints.SUPERVISORS;
  return (await axios.put(endpoints, payload)).data;
}

export async function createSupervisor(payload) {
  const endpoints = APIEndpoints.SUPERVISORS;
  return (await axios.post(endpoints, payload)).data;
}

export async function deleteSupervisor(id) {
  const endpoints = APIEndpoints.SUPERVISORS + `/${id}`;
  return (await axios.delete(endpoints)).data;
}

export async function getVehicleDetails(params) {
  const endpoints = APIEndpoints.VEHICLE_NUMBER;
  return (await axios.get(endpoints, { params })).data;
}
export async function postPickupLocations(payload) {
  const endpoint = APIEndpoints.PICKUPLOCATION;
  const method = payload.id ? 'put' : 'post';
  const response = (await axios[method](endpoint, payload)).data;
  return response;
}

export async function getDropLocations(payload) {
  const endpoint = APIEndpoints.DROPLOCATION;
  const response = (await axios.get(endpoint)).data;
  return response;
}

export async function getDeliveryList(params) {
  const endpoint = APIEndpoints.DELIVERIES;
  const response = (await axios.get(endpoint, { params })).data;
  return response;
}

export async function superVisorRequestOTP(payload) {
  const endpoint = APIEndpoints.SUPERVISOR_SEND_OTP;
  const response = (await axios.post(endpoint, payload)).data;
  return response;
}

export async function otpVerify(payload) {
  const endpoint = APIEndpoints.VERIFY_OTP;
  const response = (await axios.post(endpoint, payload)).data;
  return response;
}

export async function getTrackingDetails(id) {
  const authenticate = await axios.post(API_BASE_URL + ApiEndpoints.AUTHENTICATE);
  delete axios.defaults.headers.common.baseURL;
  axios.defaults.headers.common.Authorization = `Bearer ${authenticate?.data?.data?.accessToken}`;
  const endpoint = API_TRACKING_BASE_URL + APIEndpoints.TRACKING + `/${id}`;
  const response = (await axios.get(endpoint)).data;
  return response;
}
