import axios from 'axios';
import * as qs from 'query-string';
import { toast } from 'react-toastify';
import { APP_EVENTS } from '../Constants/Constants';
import { publish } from '../Events/AppEvents';
import errorExtractor from '../Utils/ErrorExtractor';

export const API_BASE_URL = process.env.REACT_APP_API_ENDPOINT;
export const API_TRACKING_BASE_URL = process.env.REACT_APP_TRACKING_API_ENDPOINT;

axios.defaults.paramsSerializer = (params) => {
  return qs.stringify(params, { arrayFormat: 'repeat' });
};
const Api = {
  get: (url, option, query, successCallback, errorCallback) =>
    request({
      url,
      init: {
        method: 'GET',
      },
      query,
      option,
      successCallback,
      errorCallback,
    }),
  post: (url, payload, option, query, successCallback, errorCallback) =>
    request({
      url,
      init: {
        method: 'POST',
      },
      query,
      payload,
      option,
      successCallback,
      errorCallback,
    }),
  put: (url, option, query, successCallback, errorCallback) =>
    request({
      url,
      init: {
        method: 'PUT',
      },
      query,
      option,
      successCallback,
      errorCallback,
    }),
  patch: (url, option, query, successCallback, errorCallback) =>
    request({
      url,
      init: {
        method: 'PATCH',
      },
      query,
      option,
      successCallback,
      errorCallback,
    }),
  delete: (url, option, query, successCallback, errorCallback) =>
    request({
      url,
      init: {
        method: 'DELETE',
      },
      query,
      option,
      successCallback,
      errorCallback,
    }),
};

/**
 * Header interceptor for each api
 * @param headers
 * @return {{Authorization: string, Accept: string, "Content-Type": string}}
 */
function buildHeaders(headers) {
  const token = localStorage.getItem('authenticate_token');

  const appCode = 'EPR005';
  const newHeader = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    appCode: appCode,
    ...headers,
  };

  if (token) {
    newHeader.Authorization = 'Bearer ' + token;
  }

  return newHeader;
}

function request(props) {
  const { url, init, query, payload, option, successCallback, errorCallback } = props;
  const fetchUrl = /^http/.test(url) ? url : `${API_BASE_URL}${url}`;
  return axios({
    url: fetchUrl,
    method: init.method,
    data: payload ?? option,
    params: query,
    headers: buildHeaders(init.headers),
    timeout: option && option.timeout ? option.timeout : 300000,
  })
    .then((response) => {
      successCallback && successCallback(response);
      return response;
    })
    .catch((error) => {
      errorCallback && errorCallback(error);
      // ignore global error handling
      if (option && option.ignoreError) return;
      onError(error);
    });
}

/**
 * called on api failed
 * @param error
 */
let onError = (error) => {
  if (error?.message === 'Network Error' || (error?.response && error?.response.status === 503)) {
    publish(
      APP_EVENTS.topic.SERVER_ERROR,
      APP_EVENTS.event.SERVER_DOWN_503,
      error?.response ?? error
    );
    return;
  }
  if (error?.response) {
    if (error?.response.status === 0) {
      toast.warn('Please check if you are connected to Internet');
    } else if (error?.response.status === 400) {
      const err = errorExtractor(error?.response);
      if (err?.message) {
        toast.warn(err.message);
      }
    } else if (error?.response.status === 500) {
      toast.error('Server Error');
    } else {
      console.log(error?.message);
    }
  }
  throw error;
};

export default Api;
