/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Button, Form, FormControl, InputGroup } from 'react-bootstrap';
import { useRegions } from '../../../hooks';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';

let districtOptions = [];
let stateOptions = [];
let itemOptions = [];
let browseNodeOptions = [];
let partnerOptions = [];
let providerOptions = [];
let clientIdOptions = [];

let facetList = [];

const groupStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  fontSize: 12,
};
const groupBadgeStyles = {
  backgroundColor: '#EBECF0',
  borderRadius: '2em',
  color: '#172B4D',
  display: 'inline-block',
  fontSize: 12,
  fontWeight: 'normal',
  lineHeight: '1',
  minWidth: 1,
  padding: '0.16666666666667em 0.5em',
  textAlign: 'center',
};

export const formatGroupLabel = (data) => (
  <div style={groupStyles}>
    <span>{data.label}</span>
    <span style={groupBadgeStyles}>{data.options.length}</span>
  </div>
);

const FilterCell = (props) => {
  let states = useRegions();

  const { data: browseNodes } = useSelector(({ browseNodes }) => browseNodes);

  let { clients, serviceProviders } = useSelector(({ account, serviceProvider }) => ({
    clients: account.clients,
    serviceProviders: serviceProvider.listLight,
  }));

  districtOptions.length = 0;
  stateOptions.length = 0;
  browseNodeOptions.length = 0;
  itemOptions.length = 0;
  partnerOptions.length = 0;
  providerOptions.length = 0;
  clientIdOptions.length = 0;

  let workOrderIdOptions = [
    {
      type: 'workOrderIds',
      value: 'WORKORDER1',
      title: 'Search Work Order',
      label: 'Search Work Order',
      id: 'workOrderIds',
    },
  ];
  // let deliveryIdOptions = [
  //   {
  //     type: 'deliveryIds',
  //     value: 'DELIVERY1',
  //     title: 'Search Delivery Id',
  //     label: 'Search Delivery Id',
  //     placeholder: 'Enter Delivery Id',
  //     id: 'id',
  //   },
  // ];
  let vehiclenumberOption = [
    {
      type: 'vehcleId',
      value: 'DELIVERY1',
      title: 'Search Vehicle Number',
      label: 'Search vehicle Number',
      placeholder: 'Enter Vehicle Number',
      id: 'vehicleNumber',
    },
  ];

  let recyclerOption = [
    {
      type: 'recycler',
      value: 'DELIVERY1',
      title: 'Search Recycler Name',
      label: 'Search Recycler Name',
      placeholder: 'Enter Recycler Name',
      id: 'recycler',
    },
  ];

  let poOption = [
    {
      type: 'poNumber',
      value: 'DELIVERY1',
      title: 'Search PO Number',
      label: 'Search PO Number',
      placeholder: 'Enter PO Number',
      id: 'poNumber',
    },
  ];

  states.forEach((stateObj) => {
    stateOptions.push({
      type: 'stateIds',
      value: stateObj.id.toString(),
      label: stateObj.name,
      title: stateObj.name,
    });
    stateObj.districts.forEach((d) => {
      districtOptions.push({
        type: 'districtIds',
        value: d.id.toString(),
        label: d.name,
        title: d.name,
      });
    });
  });

  browseNodes.forEach((browseNodeObj) => {
    browseNodeOptions.push({
      type: 'browseNodeIds',
      value: browseNodeObj.id.toString(),
      label: browseNodeObj.title,
      title: browseNodeObj.title,
    });
    browseNodeObj.items.forEach((itemObj) => {
      itemOptions.push({
        type: 'itemIds',
        value: itemObj.id.toString(),
        label: itemObj.name,
        title: itemObj.name,
      });
    });
  });
  clients.forEach((clientObj) => {
    clientIdOptions.push({
      type: props.deliveryIdOptionsVal ? 'clientIds' : 'fromCustomerIds',
      value: clientObj.id.toString(),
      label: clientObj.name,
      title: clientObj.name,
    });
  });

  if ((props.workordeIdOptionsVal && props.toId) || props.deliveryIdOptionsVal) {
    serviceProviders.forEach((spObj) => {
      providerOptions.push({
        type: props.deliveryIdOptionsVal ? 'clientIds' : 'toCustomerIds',
        value: spObj.id.toString(),
        label: spObj.businessName,
        title: spObj.businessName,
      });
    });
  }

  facetList = [
    ...browseNodeOptions,
    ...itemOptions,
    ...stateOptions,
    ...districtOptions,
    ...clientIdOptions,
    ...providerOptions,
  ];
  const groupedOptions = [
    {
      label: 'MATERIAL CATEGORIES',
      options: browseNodeOptions,
    },
    {
      label: 'MATERIAL ITEMS',
      options: itemOptions,
    },
  ];

  const [selectedValues, setSelectedValues] = useState([]);
  const [openInput, setOpenInput] = useState(false);
  const [openDeliveryInput, setOpenDeliveryInput] = useState(false);
  const [workOrderId, setWorkOrderId] = useState('');
  const [deliveryId, setDeliveryId] = useState('');
  const deliverylistFilters = [
    { label: 'VEHICLE NUMBER', options: vehiclenumberOption },
    // { label: 'EWAYBILL NUMBER', options: eWayBillNumberOption },
    // { label: 'RECYCLER PINCODE', options: recyclerPincodeOption },
    { label: 'RECYCLER NAME', options: recyclerOption },
    // { label: 'SOURCE PINCODE', options: sourcePincodeOption },
  ];

  if (props.workordeIdOptionsVal) {
    groupedOptions.unshift({
      label: 'WORK ORDER ID',
      options: workOrderIdOptions,
    });

    groupedOptions.push({
      label: 'SERVICE PROVIDER',
      options: providerOptions,
    });
  }
  if (props.fromIncomingOrders || props.deliveryIdOptionsVal) {
    groupedOptions.push({
      label: 'CLIENT',
      options: clientIdOptions,
    });
    groupedOptions.push({
      label: 'SERVICE PROVIDER',
      options: providerOptions,
    });
  }

  groupedOptions.push({
    label: 'STATES',
    options: stateOptions,
  });
  groupedOptions.push({
    label: 'DISTRICTS',
    options: districtOptions,
  });
  if (props.po) {
    groupedOptions.unshift({
      label: 'PO NUMBER',
      options: poOption,
    });
  }
  if (props.deliveryIdOptionsVal) {
    // groupedOptions.splice(1, 0, {
    //   label: 'DELIVERY ID',
    //   options: deliveryIdOptions,
    // });
  }
  if (props.fromExecution) {
    groupedOptions.splice(2, 4);
  }
  if (props.deliveryIdOptionsVal) {
    Object.keys(deliverylistFilters).forEach((e) => {
      groupedOptions.push(deliverylistFilters[e]);
    });
  }

  useEffect(() => {
    if (props.selected !== undefined || props.selected !== null) {
      setSelectedValues(props.selected(facetList));
    }
  }, [props.selected]);

  const cancelInput = (type) => {
    setSelectedValues([]);
    props.onFilterCellChange([]);
    props.handleInputFilterCell();
    type ? setOpenInput(false) : setOpenDeliveryInput(false);
  };
  const [fieldValues, setFieldValues] = useState({});
  const filterOnChange = (values) => {
    const isWorkOrderFilter = values.findIndex((val) => val.value === 'WORKORDER1') >= 0;

    const isDeliveryIdFilter = values.findIndex((val) => val.value === 'DELIVERY1') >= 0;
    if (isWorkOrderFilter) {
      setOpenInput(true);
      const f = values.map((val) => {
        if (val.value === 'WORKORDER1') return val;
      });
      setFieldValues(f);
      setSelectedValues([]);
    } else if (isDeliveryIdFilter) {
      setOpenDeliveryInput(true);
      const f = values.find((o) => o.value === 'DELIVERY1');
      setFieldValues(f);
      setSelectedValues([]);
    } else {
      props.onFilterCellChange(values);
      // setSelectedValues(null);
    }
  };

  useEffect(() => {
    if (props.clearData) {
      setSelectedValues([]);
      props.onFilterCellChange([]);
      setOpenInput(false);
      setOpenDeliveryInput(false);
      props.setClearData(false);
    }
  }, [props?.clearData]);
  return (
    <div>
      {openInput || openDeliveryInput ? (
        <Form.Group>
          <InputGroup>
            <FormControl
              id={fieldValues.id}
              placeholder={openInput ? 'Enter Work Order Id' : fieldValues.placeholder}
              aria-label={openInput ? 'Enter Work Order Id' : fieldValues.placeholder}
              aria-describedby="basic-addon2"
              onChange={(e) => {
                openInput
                  ? setWorkOrderId(e.target.value)
                  : setDeliveryId({
                      id: e.target.id,
                      value: e.target.value,
                    });
              }}
            />
            <InputGroup.Append>
              <Button
                variant="primary"
                onClick={() => {
                  if (openInput) {
                    props.handleInputFilterCell('workOrderIds', workOrderId);
                  } else {
                    props.handleInputFilterCell([deliveryId.id], deliveryId.value);
                  }
                }}>
                Go
              </Button>
              <Button
                variant=""
                onClick={() => {
                  console.log('yes', groupedOptions);
                  cancelInput(openInput);
                }}>
                <FontAwesomeIcon style={{ color: '#a9a9a9' }} icon={faTimesCircle} />
              </Button>
            </InputGroup.Append>
          </InputGroup>
        </Form.Group>
      ) : (
        <Select
          value={selectedValues}
          options={groupedOptions}
          className="react-select"
          classNamePrefix="select"
          formatGroupLabel={formatGroupLabel}
          isMulti
          isDisabled={props.disabled}
          onChange={(values) => {
            filterOnChange(values || []);
          }}
        />
      )}
    </div>
  );
};

export default FilterCell;
