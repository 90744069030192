import moment from 'moment';
import {
  decodeDelimitedArray,
  decodeDelimitedNumericArray,
  encodeDelimitedArray,
  encodeDelimitedNumericArray,
} from 'use-query-params';

export function getDisplayStatus(d) {
  return d.allocatedQty >= 0 && ['APPROVED', 'CREATED'].includes(d.status)
    ? d.allocatedQty === 0
      ? 'UNALLOCATED'
      : d.allocatedQty < d.qty
      ? 'PARTIALLY_ALLOCATED'
      : 'ALLOCATED'
    : d.status;
}

export function getNewDisplayStatus(d) {
  return d.allocatedQty >= 0 && ['APPROVED', 'CREATED'].includes(d.status)
    ? d.allocatedQty === 0
      ? 'AUTO_ALLOCATION'
      : d.allocatedQty < d.qty
      ? 'PARTIALLY_ALLOCATED'
      : areMandatoryCertificatesUploaded(d)
      ? d.status
      : 'CERTIFICATE_GENERATION'
    : d.status;
}

const areMandatoryCertificatesUploaded = (d) => {
  if ('deliveryDocuments' in d) {
    const certificateDocuments = d?.deliveryDocuments.filter(
      (doc) => doc.document.type.includes('CERTIFICATE') || doc.document.type.includes('ULB')
    );
    if (
      d?.assignedToWoItem?.browseNodeId === '1' ||
      d?.assignedToWoItem?.browseNodeId === '4' ||
      d?.assignedToWoItem?.browseNodeId === '6'
    ) {
      return certificateDocuments.some((doc) =>
        doc.document.type.includes('CERTIFICATE_OF_RECYCLING')
      );
    } else if (d?.assignedToWoItem?.browseNodeId === '2') {
      return certificateDocuments.some((doc) =>
        doc.document.type.includes('CO_PROCESSING_CERTIFICATE')
      );
    } else return false;
  } else return false;
};

export function getInvoiceDisplayStatus(cellInfo) {
  let displayStatus = '';
  if (cellInfo?.draft) {
    displayStatus = 'DRAFT';
  } else if (
    //cellInfo.status === 'APPROVED' &&
    Number(parseFloat(cellInfo.balanceAmount).toFixed(2)) > 0 &&
    cellInfo.balanceAmount < cellInfo.amount
  ) {
    displayStatus = 'PARTIALLY_PAID';
  } else if (
    //cellInfo.status === 'APPROVED' &&
    Number(parseFloat(cellInfo.balanceAmount).toFixed(2)) === 0
  ) {
    displayStatus = 'PAID';
  } else {
    displayStatus = cellInfo.status;
  }

  return displayStatus;
}

export const saveFile = (data, filename) => {
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename); //or any other extension
  link.click();
};

export const roundOffAmount = (amount) => {
  return Number(parseFloat(amount || 0).toFixed(2));
};

/** Uses a comma to delimit entries. e.g. ['a', 'b'] => qp?=a,b */
export const CommaArrayParam = {
  encode: (array = undefined) => encodeDelimitedArray(array, ','),

  decode: (arrayStr = undefined) => decodeDelimitedArray(arrayStr, ','),
};

/** Uses a comma to delimit entries. e.g. ['a', 'b'] => qp?=a,b */
export const CommaArrayNumberParam = {
  encode: (array = undefined) => encodeDelimitedNumericArray(array, ','),

  decode: (arrayStr = undefined) => decodeDelimitedNumericArray(arrayStr, ','),
};

export function timeConvert(n) {
  var num = n;
  var hours = num / 60;
  var rhours = Math.floor(hours);
  var minutes = (hours - rhours) * 60;
  var rminutes = Math.round(minutes);
  return rhours + 'hr' + rminutes + 'm';
}

export async function extractAddressResponse(geoCodeResponse, lat, lng) {
  const addr = {
    cityName: '',
    latLng: { lat: '', lng: '' },
    sourceName: '',
    sourceType: '',
    state: { name: '' },
    street: '',
    zipCode: '',
  };
  addr.latLng.lat = lat;
  addr.latLng.lng = lng;
  geoCodeResponse[0].address_components.forEach((item) => {
    if (item.types.includes('postal_code')) {
      addr.zipCode = item.long_name;
    }
    if (item.types.includes('locality')) {
      addr.cityName = item.long_name;
    }
    if (item.types.includes('administrative_area_level_1')) {
      addr.state.name = item.long_name;
    }
  });
  // const toRemove = [addr.city, addr.state, addr.zipCode, addr.country];
  addr.streetAddress = geoCodeResponse[0]?.formatted_address;

  return addr;
}
export const formatIndianNumber = (number) => {
  if (number) {
    let numstr = Number(number);
    return new Intl.NumberFormat('en-IN', {
      style: 'decimal',
      // currency: 'INR',
      minimumFractionDigits: 0,
    }).format(numstr);
  } else {
    return '';
  }
};
export function DateComponent({ date }) {
  // Function to get the day with the correct ordinal suffix
  const getDayWithSuffix = (date) => {
    const day = date.date();
    if (day > 3 && day < 21) return `${day}th`; // Handle 4th-20th (special case)
    switch (day % 10) {
      case 1:
        return `${day}st`;
      case 2:
        return `${day}nd`;
      case 3:
        return `${day}rd`;
      default:
        return `${day}th`;
    }
  };
  const formattedDate =
    getDayWithSuffix(moment(date)) +
    ' ' +
    moment(date).format('MMM') +
    ', ' +
    moment(date).format('h:mm A');

  return formattedDate;
}

export const roundOffQuantityMT = (qty) => {
  return Number(parseFloat(qty || 0).toFixed(3));
};
