import { Button as Btn } from '@material-ui/core';
import React from 'react';
import { Modal } from 'react-bootstrap';
import './PlanningModule.scss';

function ReconfirmModal(props) {
  const { handleCloseModal, open, generateWorkOrder, errorMsg, isLoading } = props;

  const confirmCall = async () => {
    handleCloseModal(false);
    generateWorkOrder();
  };

  return (
    <Modal
      show={open}
      onHide={() => handleCloseModal(false)}
      centered
      dialogClassName="cancel-onboard-modal">
      <div style={{ backgroundColor: '#FFF' }} />

      <Modal.Body className="cancel-onboard-modal border-0 px-3 pt-0">
        <h6>Are you sure you want to continue?</h6>
        <div>
          {errorMsg}
          <br />
        </div>
      </Modal.Body>
      <Modal.Footer className="cancel-onboard-modal-footer border-0 px-3 pt-0">
        <Btn
          disabled={isLoading}
          className="cancel-onboard-primary-button"
          onClick={() => {
            confirmCall();
          }}>
          Yes,Continue
        </Btn>
        <Btn onClick={() => handleCloseModal(false)} className="cancel-onboard-secondary-button">
          Cancel
        </Btn>
      </Modal.Footer>
    </Modal>
  );
}

export default ReconfirmModal;
