import { AppBar, IconButton, makeStyles, Toolbar } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { ChevronLeft } from '@material-ui/icons';
import { isNull } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { appRoutes } from '../routes';
import { Colors } from '../theme/Colors';

const useStyles = makeStyles({
  grow: {
    flexGrow: 1,
  },
  title: {
    display: 'block',
    fontSize: '1.273rem',
    textAlign: 'center',
  },
  header: {
    backgroundColor: Colors.white,
    color: '#2e374d',
    borderBottom: `1px solid ${Colors.appBgColor}`,
  },
});

function AppHeader({ leftRender, rightRender, titleRender, hideBack }) {
  const classes = useStyles();
  const { pathname } = useLocation();
  const { goBack } = useHistory();
  const history = useHistory();
  const params = new URLSearchParams(window.location.search);
  const [activeRoute, setActiveRoute] = useState();
  useEffect(() => {
    const route = appRoutes.find((r) => r.path === pathname);
    setActiveRoute(route);
  }, [pathname]);

  if (activeRoute?.hideHeader) {
    return null;
  }

  const handleURL = () => {
    if (params.get('isCreated') === 'created' || activeRoute?.name === 'Requires your Attention') {
      history.push('/tabs/dashboard');
    } else if (params.get('requires') === 'true' || params.get('delivery') === 'true') {
      const searchedQuery = window.location.search.slice(1);
      if (params.get('isSearch') === 'true')
        history.push({
          pathname:
            params.get('requires') === 'true' ? `/require-attention-list` : `/deliveries-list`,
          search: `${searchedQuery}&isSearchApplied=true`,
        });
      else
        history.push({
          pathname:
            params.get('requires') === 'true' ? `/require-attention-list` : `/deliveries-list`,
          search: `${searchedQuery}&isFilterApplied=true`,
        });
    } else goBack();
  };

  return (
    <AppBar elevation={0} position={'fixed'} className={classes.header}>
      <Toolbar>
        <div>
          {isNull(leftRender) ? null : leftRender ? (
            leftRender(activeRoute)
          ) : (
            <IconButton
              edge="start"
              aria-label="account of current user"
              aria-haspopup="true"
              onClick={handleURL}
              color="inherit">
              <ChevronLeft color={'primary'} />
            </IconButton>
          )}
        </div>
        <div className={classes.grow} />
        <Typography className={classes.title} variant={'subtitle2'} noWrap>
          {titleRender ? titleRender(activeRoute) : activeRoute?.name}
        </Typography>
        <div className={classes.grow} />
        <div>{rightRender ? rightRender(activeRoute) : null}</div>
      </Toolbar>
    </AppBar>
  );
}
export default AppHeader;
