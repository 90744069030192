import React from 'react';
import { Button, Form, Modal } from 'react-bootstrap';

function renderBody(workOrder, workOrderForm, mode, handleClose) {
  switch (mode) {
    case 'view':
      return (
        <>
          <Modal.Header closeButton>
            <Modal.Title>
              <h5>View Comments</h5>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="py-4">
            <Form.Control
              as="textarea"
              rows="5"
              id="Category"
              type="text"
              textarea
              value={workOrder?.extraComments}
              disabled
              style={{ backgroundColor: '#ffffff' }}
            />
          </Modal.Body>
        </>
      );
    case 'add':
      return (
        <>
          <Modal.Header closeButton>
            <Modal.Title>
              <h5>Add Comments</h5>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form className="px-2">
              <Form.Group className="mb-4">
                <Form.Control
                  as="textarea"
                  rows="5"
                  id="Category"
                  type="text"
                  textarea
                  onChange={(e) => {
                    workOrderForm.setFieldValue('extraComments', e.target.value);
                  }}
                  value={workOrderForm.values?.extraComments}
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={handleClose}>
              Add Comment
            </Button>
          </Modal.Footer>
        </>
      );
    case 'edit':
      return (
        <>
          <Modal.Header closeButton>
            <Modal.Title>
              <h5>{!workOrder?.extraComments ? 'Add Comments' : 'Edit Comments'}</h5>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form className="px-2">
              <Form.Group className="mb-4">
                <Form.Control
                  as="textarea"
                  rows="5"
                  id="Category"
                  type="text"
                  textarea
                  onChange={(e) => {
                    workOrderForm.setFieldValue('extraComments', e.target.value);
                  }}
                  value={workOrderForm.values?.extraComments}
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={handleClose}>
              {!workOrder?.extraComments ? 'Add Comment' : 'Edit Comment'}
            </Button>
          </Modal.Footer>
        </>
      );

    default:
      return;
  }
}

function CommentsModal({ modalShow, handleClose, workOrderForm, workOrder, mode }) {
  return (
    <Modal show={modalShow} size="lg" centered className="p-2" onHide={handleClose}>
      {renderBody(workOrder, workOrderForm, mode, handleClose)}
    </Modal>
  );
}

export default CommentsModal;
