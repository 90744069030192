import classNames from 'classnames';
import { flatMap, sortBy } from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import Select from 'react-select';

const DropdownMaterial = ({ ...props }) => {
  let { materialItems } = useSelector(({ browseNodes }) => ({
    materialCategories: sortBy(
      browseNodes?.data?.filter((category) => category?.id !== '3' && category?.id !== '5'),
      'title'
    ),
    materialItems: flatMap(
      sortBy(
        browseNodes?.data?.filter((category) => category?.id !== '3' && category?.id !== '5'),
        'title'
      )?.map((data) => data?.items)
    ),
    nodes: browseNodes?.data,
  }));

  if (props.filterIds && props.filterIds.length > 0) {
    materialItems = materialItems.filter((material) => props.filterIds.indexOf(material.id) >= 0);
  }

  return (
    <>
      <Select
        {...props}
        className={classNames('react-select', {
          'is-invalid': props.isInvalid,
        })}
        classNamePrefix="select"
        value={
          typeof props.value !== 'undefined' &&
          props.value && {
            ...materialItems?.find(
              (o) =>
                o?.name === props.value ||
                o?.name === props.value.sourceState ||
                o?.id === props.value ||
                o?.id === props.value.sourceState
            ),
          }
        }
        options={materialItems}
        getOptionLabel={(option) => `${option?.name || option?.title || ''}`}
        getOptionValue={(option) => `${option?.id || ''}`}
        noOptionsMessage={() => 'Materials not found!'}
        components={{
          IndicatorSeparator: null,
        }}
      />
    </>
  );
};

export default DropdownMaterial;
