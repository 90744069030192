/* eslint-disable react-hooks/exhaustive-deps */
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Button, Form, FormControl, InputGroup } from 'react-bootstrap';
import Select from 'react-select';
import { groupedOptions } from '../../Constants/Constants';
import { useAuthority } from '../HasAuthority/HasAuthorityNew';

const groupStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  fontSize: 12,
};
const groupBadgeStyles = {
  backgroundColor: '#EBECF0',
  borderRadius: '2em',
  color: '#172B4D',
  display: 'inline-block',
  fontSize: 12,
  fontWeight: 'normal',
  lineHeight: '1',
  minWidth: 1,
  padding: '0.16666666666667em 0.5em',
  textAlign: 'center',
};

export const formatGroupLabel = (data) => (
  <div style={groupStyles}>
    <span>{data.label}</span>
    <span style={groupBadgeStyles}>{data.options.length}</span>
  </div>
);

const ServiceProviderCell = (props) => {
  const [selectedValues, setSelectedValues] = useState([]);
  const { hasPermission } = useAuthority();
  const [inputArray, setInputArray] = useState([]);
  const [inputVal, setInputVal] = useState();
  const [openInput, setOpenInput] = useState(false);
  const { hasAuth } = useAuthority();
  const hasAllBrandsPermissions = hasPermission({
    permissionsAllowed: [
      {
        groupName: 'Execute_Targets',
        moduleName: 'Clients',
        name: 'all_brands',
      },
    ],
  });
  if (!hasAllBrandsPermissions) {
    const index = groupedOptions.findIndex((option) => option.id === 'spocName.contains');
    if (index > -1) {
      groupedOptions.splice(index, 1);
    }
  }
  useEffect(() => {
    if (props.selected !== undefined || props.selected !== null) {
      setSelectedValues(props.selected);
    }
  }, [props.selected]);

  const cancelInput = () => {
    setSelectedValues([]);
    props.onFilterCellChange([]);
    setOpenInput(false);
  };

  const filterOnChange = (values) => {
    setOpenInput(values.length > 0);
    setInputArray(values);
  };

  useEffect(() => {
    if (props.clearData) {
      setSelectedValues([]);
      props.onFilterCellChange([]);
      setOpenInput(false);

      props.setClearData(false);
    }
  }, [props?.clearData]);

  const onGo = (id) => {
    props.onFilterCellChange([{ type: id, value: inputVal }]);
  };

  return (
    <div>
      {openInput ? (
        inputArray.length > 0 &&
        inputArray.map((mapData, idx) => (
          <Form.Group key={idx}>
            <InputGroup>
              <FormControl
                id={mapData.id}
                placeholder={mapData.placeholder}
                aria-label={mapData.title}
                aria-describedby="basic-addon2"
                onChange={(e) => {
                  setInputVal(e.target.value);
                }}
              />
              <InputGroup.Append>
                <Button
                  variant="primary"
                  onClick={() => {
                    onGo(mapData.id);
                  }}>
                  Go
                </Button>
                <Button variant="" onClick={() => cancelInput()}>
                  <FontAwesomeIcon style={{ color: '#a9a9a9' }} icon={faTimesCircle} />
                </Button>
              </InputGroup.Append>
            </InputGroup>
          </Form.Group>
        ))
      ) : (
        <Select
          value={selectedValues}
          options={groupedOptions}
          className="react-select"
          classNamePrefix="select"
          formatGroupLabel={formatGroupLabel}
          isMulti
          isDisabled={props.disabled}
          onChange={(values) => {
            filterOnChange(values || []);
          }}
        />
      )}
    </div>
  );
};

export default ServiceProviderCell;
