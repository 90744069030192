import axios from 'axios';
import APIEndpoints from '../Config/ApiEndpoints.const';

const signedUrlMap = {
  DELIVERY: APIEndpoints.EPR_SIGNED_URL,
  AGREEMENT: APIEndpoints.STAKEHOLDERS_SIGNED_URL,
  STAKEHOLDERS: APIEndpoints.STAKEHOLDERS_SIGNED_URL,
  CUSTOMER: APIEndpoints.STAKEHOLDERS_SIGNED_URL,
  WORK_ORDER: APIEndpoints.EPR_SIGNED_URL,
  INVOICE: APIEndpoints.EPR_SIGNED_URL,
  ULB: APIEndpoints.EPR_SIGNED_URL,
  CERTIFICATE: APIEndpoints.EPR_SIGNED_URL,
  CREDIT_INFORMATION: APIEndpoints.EPR_SIGNED_URL,
};
/**
 * Api call to get Signed URL
 * @param fileDetails
 * @param successCallback
 * @param failureCallback
 */
export const getSignedURL = (fileDetails, successCallback, failureCallback) => {
  axios.post(signedUrlMap[fileDetails.entity] || signedUrlMap['STAKEHOLDERS'], fileDetails).then(
    (response) => {
      if (response.status === 200) {
        successCallback(response);
      }
    },
    (error) => {
      failureCallback(error);
    }
  );
};

/**
 * Upload file to S3 bucket
 * @param fileDetails
 * @param successCallback
 * @param failureCallback
 */
export const uploadFileToS3 = (
  { signedURL, file, fileType },
  signedURLResponse,
  successCallback,
  failureCallback,
  progressCallback
) => {
  const instance = axios.create({
    headers: { 'Content-Type': fileType },
    timeout: 60000,
    onUploadProgress: progressCallback,
  });

  delete instance.defaults.headers.common.Authorization;

  instance
    .put(signedURL, file)
    .then((response) => {
      if (response.status === 200) {
        successCallback(signedURLResponse);
      }
    })
    .catch((error) => {
      failureCallback(error);
    });
};

/**
 *
 * @param fileDetails
 * @param successCallback
 * @param failureCallback
 */
export const uploadDocument = (
  fileDetails,
  file,
  signedDoc,
  successCallback,
  failureCallback,
  progressCallback
) => {
  axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
    'authenticate_token'
  )}`;
  getSignedURL(
    fileDetails,
    (signedURLResponse) => {
      signedDoc(signedURLResponse.data);
      const s3Details = {
        signedURL: signedURLResponse.data.signedUrl,
        file: file,
        fileType: file.type,
      };
      uploadFileToS3(
        s3Details,
        signedURLResponse,
        successCallback,
        failureCallback,
        progressCallback
      );
    },
    failureCallback
  );
};

export const bulkUploadDocuments = async (
  serviceProviderId,
  providerName,
  file,
  batchDate,
  recyclerId,
  recyclerName,
  materialId,
  loaderFn
) => {
  const TOKEN = localStorage.getItem('authenticate_token');

  const instance = axios.create({
    headers: { 'Content-Type': 'multipart/form-data', Authorization: `Bearer ${TOKEN}` },
    timeout: 180000,
    onUploadProgress: (e) => {
      if (loaderFn !== undefined) {
        loaderFn(e);
      }
    },
  });

  const form = new FormData();
  form.append('file', file[0]);
  form.append('batchDate', batchDate);
  if (!!serviceProviderId) form.append('providerId', serviceProviderId);
  if (!!providerName) form.append('serviceProviderName', providerName);
  form.append('recyclerCustomerId', recyclerId);
  form.append('recyclerName', recyclerName);
  form.append('materialId', materialId);

  const response = await instance.post(APIEndpoints.BULK_UPLOAD, form);
  return response;
};
